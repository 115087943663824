document.addEventListener("DOMContentLoaded", () => {
  const button = document.getElementById("coupon-code-copy-button");

  if (button) {
    const code = button.dataset.code;
    const copyMessageElement = button.querySelector(
      "#coupon-code-copy-message",
    );
    const copiedMessageElement = button.querySelector(
      "#coupon-code-copied-message",
    );

    if (!code || !copyMessageElement || !copiedMessageElement) {
      throw new Error("Code or message elements not defined");
    }

    button.addEventListener("click", () => {
      button.classList.replace("bg-ti-standard", "bg-ti-functional-success");
      button.classList.replace(
        "border-ti-standard",
        "border-ti-functional-success",
      );
      copyMessageElement.classList.replace("inline-block", "hidden");
      copiedMessageElement.classList.replace("hidden", "inline-block");

      navigator.clipboard.writeText(code);

      setTimeout(() => {
        button.classList.replace("bg-ti-functional-success", "bg-ti-standard");
        button.classList.replace(
          "border-ti-functional-success",
          "border-ti-standard",
        );
        copiedMessageElement.classList.replace("inline-block", "hidden");
        copyMessageElement.classList.replace("hidden", "inline-block");
      }, 2500);
    });
  }
});
